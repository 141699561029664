<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-form ref="trocarSenhaForm" @submit.prevent="trocarSenha">
          <v-card rounded width="1000">
            <v-card-title class="justify-center titulo"
              >Alterar Senha
            </v-card-title>
            <v-card-subtitle class="text-center">
              Escolha uma nova senha. Ao confirmar a sua nova senha você será
              direcionado à página inicial, sua sessão expirará e será
              necessário efetuar o login novamente.
            </v-card-subtitle>
            <v-divider class="my-3"/>
            <div class="text-end text-caption mr-9">
              * Campos obrigatórios
            </div>
            <v-card-text>
              <v-text-field
                outlined
                dense
                type="password"
                v-model="senhaAtual"
                minlength="8"
                :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                label="Senha Atual *"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                outlined
                dense
                type="password"
                v-model="novaSenha"
                minlength="8"
                hint="Mínimo de 8 caracteres"
                :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                label="Nova Senha *"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="confimacaoNovaSenha"
                :rules="[
                  campoObrigatorioRule,
                  camposIguaisRule(
                    confimacaoNovaSenha,
                    novaSenha,
                    'Senha diferente da informada!'
                  )
                ]"
                type="password"
                minlength="8"
                hint="Digite novamente a nova senha"
                label="Confirmação Senha *"
                validate-on-blur
              ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                :loading="loading"
                class="text-center"
                color="success"
                type="submit"
                >Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import usuarioService from "@/services/usuario.service";
import { mapActions } from "vuex";

export default {
  name: "TrocarSenha",

  data() {
    return {
      senhaAtual: "",
      novaSenha: "",
      confimacaoNovaSenha: "",
      loading: false
    };
  },

  methods: {
    ...rules,

    ...mapActions("autenticacao", ["logout"]),
    ...mapActions("avisos", ["exibirAviso"]),

    trocarSenha() {
      if (this.$refs.trocarSenhaForm.validate()) {
        this.loading = true;
        usuarioService
          .trocarSenha(this.senhaAtual, this.novaSenha)
          .then(() => {
            this.exibirAviso({
              mensagem:
                "Senha alterada com sucesso. Por favor, faça login novamente",
              tipo: "success"
            });
            this.logout();
          })
          .catch(() => {
            this.exibirAviso({
              mensagem: "Senha atual inválida. Tente novamente!",
              tipo: "error"
            });
          });

        this.loading = false;
      }
    }
  }
};
</script>
