var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-form',{ref:"trocarSenhaForm",on:{"submit":function($event){$event.preventDefault();return _vm.trocarSenha.apply(null, arguments)}}},[_c('v-card',{attrs:{"rounded":"","width":"1000"}},[_c('v-card-title',{staticClass:"justify-center titulo"},[_vm._v("Alterar Senha ")]),_c('v-card-subtitle',{staticClass:"text-center"},[_vm._v(" Escolha uma nova senha. Ao confirmar a sua nova senha você será direcionado à página inicial, sua sessão expirará e será necessário efetuar o login novamente. ")]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"text-end text-caption mr-9"},[_vm._v(" * Campos obrigatórios ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"password","minlength":"8","rules":[_vm.campoObrigatorioRule, _vm.tamanhoSenhaRule],"label":"Senha Atual *","validate-on-blur":""},model:{value:(_vm.senhaAtual),callback:function ($$v) {_vm.senhaAtual=$$v},expression:"senhaAtual"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"password","minlength":"8","hint":"Mínimo de 8 caracteres","rules":[_vm.campoObrigatorioRule, _vm.tamanhoSenhaRule],"label":"Nova Senha *","validate-on-blur":""},model:{value:(_vm.novaSenha),callback:function ($$v) {_vm.novaSenha=$$v},expression:"novaSenha"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[
                _vm.campoObrigatorioRule,
                _vm.camposIguaisRule(
                  _vm.confimacaoNovaSenha,
                  _vm.novaSenha,
                  'Senha diferente da informada!'
                )
              ],"type":"password","minlength":"8","hint":"Digite novamente a nova senha","label":"Confirmação Senha *","validate-on-blur":""},model:{value:(_vm.confimacaoNovaSenha),callback:function ($$v) {_vm.confimacaoNovaSenha=$$v},expression:"confimacaoNovaSenha"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"text-center",attrs:{"loading":_vm.loading,"color":"success","type":"submit"}},[_vm._v("Confirmar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }